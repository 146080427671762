.vis-overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  /* Must be displayed above for example selected Timeline items */
  z-index: 10;
}

.vis-active {
  box-shadow: 0 0 10px #86d5f8;
}
