div.vis-color-picker {
  position: absolute;
  top: 0px;
  left: 30px;
  margin-top: -140px;
  margin-left: 30px;
  width: 310px;
  height: 444px;
  z-index: 1;
  padding: 10px;
  border-radius: 15px;
  background-color: #ffffff;
  display: none;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px 0px;
}

div.vis-color-picker div.vis-arrow {
  position: absolute;
  top: 147px;
  left: 5px;
}

div.vis-color-picker div.vis-arrow::after,
div.vis-color-picker div.vis-arrow::before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

div.vis-color-picker div.vis-arrow:after {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #ffffff;
  border-width: 30px;
  margin-top: -30px;
}

div.vis-color-picker div.vis-color {
  position: absolute;
  width: 289px;
  height: 289px;
  cursor: pointer;
}

div.vis-color-picker div.vis-brightness {
  position: absolute;
  top: 313px;
}

div.vis-color-picker div.vis-opacity {
  position: absolute;
  top: 350px;
}

div.vis-color-picker div.vis-selector {
  position: absolute;
  top: 137px;
  left: 137px;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  border: 1px solid #ffffff;
  background: #4c4c4c; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #4c4c4c 0%,
    #595959 12%,
    #666666 25%,
    #474747 39%,
    #2c2c2c 50%,
    #000000 51%,
    #111111 60%,
    #2b2b2b 76%,
    #1c1c1c 91%,
    #131313 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #4c4c4c),
    color-stop(12%, #595959),
    color-stop(25%, #666666),
    color-stop(39%, #474747),
    color-stop(50%, #2c2c2c),
    color-stop(51%, #000000),
    color-stop(60%, #111111),
    color-stop(76%, #2b2b2b),
    color-stop(91%, #1c1c1c),
    color-stop(100%, #131313)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    #4c4c4c 0%,
    #595959 12%,
    #666666 25%,
    #474747 39%,
    #2c2c2c 50%,
    #000000 51%,
    #111111 60%,
    #2b2b2b 76%,
    #1c1c1c 91%,
    #131313 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    #4c4c4c 0%,
    #595959 12%,
    #666666 25%,
    #474747 39%,
    #2c2c2c 50%,
    #000000 51%,
    #111111 60%,
    #2b2b2b 76%,
    #1c1c1c 91%,
    #131313 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    #4c4c4c 0%,
    #595959 12%,
    #666666 25%,
    #474747 39%,
    #2c2c2c 50%,
    #000000 51%,
    #111111 60%,
    #2b2b2b 76%,
    #1c1c1c 91%,
    #131313 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    #4c4c4c 0%,
    #595959 12%,
    #666666 25%,
    #474747 39%,
    #2c2c2c 50%,
    #000000 51%,
    #111111 60%,
    #2b2b2b 76%,
    #1c1c1c 91%,
    #131313 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313',GradientType=0 ); /* IE6-9 */
}

div.vis-color-picker div.vis-new-color {
  position: absolute;
  width: 140px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  top: 380px;
  left: 159px;
  text-align: right;
  padding-right: 2px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.4);
  vertical-align: middle;
  line-height: 20px;
}

div.vis-color-picker div.vis-initial-color {
  position: absolute;
  width: 140px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  top: 380px;
  left: 10px;
  text-align: left;
  padding-left: 2px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.4);
  vertical-align: middle;
  line-height: 20px;
}

div.vis-color-picker div.vis-label {
  position: absolute;
  width: 300px;
  left: 10px;
}

div.vis-color-picker div.vis-label.vis-brightness {
  top: 300px;
}

div.vis-color-picker div.vis-label.vis-opacity {
  top: 338px;
}

div.vis-color-picker div.vis-button {
  position: absolute;
  width: 68px;
  height: 25px;
  border-radius: 10px;
  vertical-align: middle;
  text-align: center;
  line-height: 25px;
  top: 410px;
  border: 2px solid #d9d9d9;
  background-color: #f7f7f7;
  cursor: pointer;
}

div.vis-color-picker div.vis-button.vis-cancel {
  /*border:2px solid #ff4e33;*/
  /*background-color: #ff7761;*/
  left: 5px;
}
div.vis-color-picker div.vis-button.vis-load {
  /*border:2px solid #a153e6;*/
  /*background-color: #cb8dff;*/
  left: 82px;
}
div.vis-color-picker div.vis-button.vis-apply {
  /*border:2px solid #4588e6;*/
  /*background-color: #82b6ff;*/
  left: 159px;
}
div.vis-color-picker div.vis-button.vis-save {
  /*border:2px solid #45e655;*/
  /*background-color: #6dff7c;*/
  left: 236px;
}

div.vis-color-picker input.vis-range {
  width: 290px;
  height: 20px;
}

/* TODO: is this redundant?
div.vis-color-picker input.vis-range-brightness {
  width: 289px !important;
}


div.vis-color-picker input.vis-saturation-range {
  width: 289px !important;
}*/
