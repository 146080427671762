div.vis-network div.vis-manipulation {
  box-sizing: content-box;

  border-width: 0;
  border-bottom: 1px;
  border-style: solid;
  border-color: #d6d9d8;
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #ffffff 0%,
    #fcfcfc 48%,
    #fafafa 50%,
    #fcfcfc 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #ffffff),
    color-stop(48%, #fcfcfc),
    color-stop(50%, #fafafa),
    color-stop(100%, #fcfcfc)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    #ffffff 0%,
    #fcfcfc 48%,
    #fafafa 50%,
    #fcfcfc 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    #ffffff 0%,
    #fcfcfc 48%,
    #fafafa 50%,
    #fcfcfc 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    #ffffff 0%,
    #fcfcfc 48%,
    #fafafa 50%,
    #fcfcfc 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #fcfcfc 48%,
    #fafafa 50%,
    #fcfcfc 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#fcfcfc',GradientType=0 ); /* IE6-9 */

  padding-top: 4px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 28px;
}

div.vis-network div.vis-edit-mode,
div.vis-network button.vis-edit-mode {
  position: absolute;
  left: 0;
  top: 5px;
  height: 30px;
}

/* FIXME: shouldn't the vis-close button be a child of the vis-manipulation div? */

div.vis-network button.vis-close {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;

  background-color: transparent;
  background-position: 20px 3px;
  background-repeat: no-repeat;
  background-image: inline("cross.png");
  border: none;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div.vis-network button.vis-close:hover {
  opacity: 0.6;
}

div.vis-network div.vis-manipulation button.vis-button,
div.vis-network div.vis-edit-mode button.vis-button {
  float: left;
  font-family: verdana;
  font-size: 12px;
  border: none;
  box-sizing: content-box;
  -moz-border-radius: 15px;
  border-radius: 15px;
  background-color: transparent;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
  padding: 0px 8px 0px 8px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div.vis-network div.vis-manipulation button.vis-button:hover {
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
}

div.vis-network div.vis-manipulation button.vis-button:active {
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
}

div.vis-network div.vis-manipulation button.vis-button.vis-back {
  background-image: inline("backIcon.png");
}

div.vis-network div.vis-manipulation div.vis-none:hover {
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0);
  cursor: default;
}
div.vis-network div.vis-manipulation div.vis-none:active {
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0);
}
div.vis-network div.vis-manipulation div.vis-none {
  padding: 0px;
  line-height: 23px;
}
div.vis-network div.vis-manipulation div.notification {
  margin: 2px;
  font-weight: bold;
}

div.vis-network div.vis-manipulation button.vis-button.vis-add {
  background-image: inline("addNodeIcon.png");
}

div.vis-network div.vis-manipulation button.vis-button.vis-edit,
div.vis-network div.vis-edit-mode button.vis-button.vis-edit {
  background-image: inline("editIcon.png");
}

div.vis-network div.vis-edit-mode button.vis-button.vis-edit.vis-edit-mode {
  background-color: #fcfcfc;
  border: 1px solid #cccccc;
}

div.vis-network div.vis-manipulation button.vis-button.vis-connect {
  background-image: inline("connectIcon.png");
}

div.vis-network div.vis-manipulation button.vis-button.vis-delete {
  background-image: inline("deleteIcon.png");
}
/* top right bottom left */
div.vis-network div.vis-manipulation div.vis-label,
div.vis-network div.vis-edit-mode div.vis-label {
  margin: 0 0 0 23px;
  line-height: 25px;
}
div.vis-network div.vis-manipulation div.vis-separator-line {
  float: left;
  display: inline-block;
  width: 1px;
  height: 21px;
  background-color: #bdbdbd;
  margin: 0px 7px 0 15px; /*top right bottom left*/
}

/* TODO: is this redundant?
div.network-navigation_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
*/
