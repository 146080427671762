div.vis-network div.vis-navigation div.vis-button {
  width: 34px;
  height: 34px;
  -moz-border-radius: 17px;
  border-radius: 17px;
  position: absolute;
  display: inline-block;
  background-position: 2px 2px;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div.vis-network div.vis-navigation div.vis-button:hover {
  box-shadow: 0 0 3px 3px rgba(56, 207, 21, 0.3);
}

div.vis-network div.vis-navigation div.vis-button:active {
  box-shadow: 0 0 1px 3px rgba(56, 207, 21, 0.95);
}

div.vis-network div.vis-navigation div.vis-button.vis-up {
  background-image: inline("upArrow.png");
  bottom: 50px;
  left: 55px;
}
div.vis-network div.vis-navigation div.vis-button.vis-down {
  background-image: inline("downArrow.png");
  bottom: 10px;
  left: 55px;
}
div.vis-network div.vis-navigation div.vis-button.vis-left {
  background-image: inline("leftArrow.png");
  bottom: 10px;
  left: 15px;
}
div.vis-network div.vis-navigation div.vis-button.vis-right {
  background-image: inline("rightArrow.png");
  bottom: 10px;
  left: 95px;
}
div.vis-network div.vis-navigation div.vis-button.vis-zoomIn {
  background-image: inline("plus.png");
  bottom: 10px;
  right: 15px;
}
div.vis-network div.vis-navigation div.vis-button.vis-zoomOut {
  background-image: inline("minus.png");
  bottom: 10px;
  right: 55px;
}
div.vis-network div.vis-navigation div.vis-button.vis-zoomExtends {
  background-image: inline("zoomExtends.png");
  bottom: 50px;
  right: 15px;
}
